.bg-modules {
    padding-top: 80px;
}

.card-module {
    border: 1px solid #CCCCCC;
}

.btn-module {
    color: #FC7429;
    border-radius: 5px;
    border: 1px solid #FC7429;
    box-shadow: none;
    font-family: Sora;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
}

.btn-module:hover {
    color: #FC7429;
    border: 1px solid #FC7429;
    box-shadow: 0px 16px 40px 0px #7090B033;
}

@media (min-width: 768px) {
    .w-md-100 {
      width: 100% !important;
    }
}

@media (min-width: 1920px) {
    .bg-modules {
      padding-right: 11rem !important;
      padding-left: 11rem !important;
    }
  }