.accordion-button.no-arrow::after {
  display: none;
}

.accordion-button.no-arrow {
  background-color: white;
}

#accordion-show-more .accordion-item {
  border: none !important;
}

#accordion-show-more {
  margin-left: -12px;
  margin-right: -35px;
}

.bg-red {
  background-color: red;
}

#accordion-show-more .accordion-button {
  background-color: white !important;
  color: #3495e2;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.accordion-button:focus {
  box-shadow: none;
  color: black;
}

.accordion-button:not(.collapsed) {
  background-color: white;
  box-shadow: none;
  color: black;
}

.faq-answer-font {
  font-size: 14px;
}
