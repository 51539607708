.bg-jumbotron {
  /* background: linear-gradient(180deg, #fa6a0e 0%, #f8b219 72.1%, #ed6c22 100%); */
  /* box-shadow: inset 0px -200px 0px 0px #fff7e9; */
  background-color: #F3F3F3;
  background-size: contain;
  overflow: hidden;
}

.bg-glass {
  /* Crad */
  background: linear-gradient(
    112.91deg,
    rgba(255, 255, 255, 0.204) 75%,
    rgba(255, 255, 255, 0) 111.71%
  );
  border-radius: 0px 0px 50px 50px;
  border: 0.1px solid;
/* 
  border-image-source: linear-gradient(
    110.65deg,
    rgba(255, 255, 255, 0.5) 1.16%,
    rgba(255, 255, 255, 0.23) 100%
  ); */
}

#vector-1 {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.bg-jumbotron .cta {
  position: relative;
  z-index: 1;
}

.jumbotron-image {
  /* add right and bottom shadow effect */
  filter: drop-shadow(3px 7px 27px rgba(0, 0, 0, 0.2))
}

.title-gradient {
  background-image: linear-gradient(90deg, #B65824 0%, #221E1E 100%);
  color: transparent;
  background-clip: text;
}

.jumbotron-description {
  color: #686869;
  line-height: 1.5;
}

.btn-jumbotron-orange {
  border-radius: 50px;
  background-color: #FC7429;
  color: #fff;
  border: 2px solid #FC7429;
}

.btn-jumbotron-white {
  border-radius: 50px;
  background-color: #fff;
  color: #050E50;
  border: 2px solid #B9BABA;
}

.btn-jumbotron-orange:hover {
  color: #fff;
  background-color: #FC7429;
  box-shadow: 0px 4px 16px 0px #00000033, 0 0 0 2px #BD571F;
}

.btn-jumbotron-white:hover {
  color: #050E50;
  background-color: #fff;
  box-shadow: 0px 4px 16px 0px #00000033, 0 0 0 2px #050E50;
}

.ellips-bottom {
  width: calc(100% - var(--bs-gutter-x));
  aspect-ratio: 1/1;
  /* height: 100vh; */
  top: 50%;
  border-radius: 50%;
  position: absolute;
  overflow: visible;
  background: radial-gradient(50% 50% at 50% 50%, #F8AF18 18%, #FA7A10 100%);
}
.video-seeker::-webkit-slider-thumb {
  background-color: #009EF7 !important;
  width: 20px;
  height: 20px;
  margin-top: calc(max((5px - 1px - 1px) * 0.5, 0px) - 20px * 0.5 + 2px);
}
.video-seeker::-moz-range-thumb { 
  background-color: #009EF7 !important;
  width: 20px;
  height: 20px;
  margin-top: calc(max((5px - 1px - 1px) * 0.5, 0px) - 20px * 0.5 + 2px);
}
.video-seeker::-webkit-slider-runnable-track { 
  height: 5px;
  background-color: #fff;
 }
.video-seeker::-moz-range-track { 
  height: 7px;
  background-color: #fff;
 }
.video-seeker::-ms-track { 
  height: 7px;
  background-color: #fff;
 }
 .btn-volume {
  position: absolute;
  z-index: 1;
  bottom: 4%;
  right: 2%;
  background: white;
  border-radius: 50%;
  opacity: 50%;
 }

 @media (max-width: 768px) {
  .title-jumbotron {
    font-size: 2.25rem;
    width: 100% !important;
  }
  .ellips-bottom {
    top: 80% !important;
  }
}

@media (min-width: 768px) {
  .title-jumbotron {
    font-size: 3rem;
    width: 75% !important;
  }
  .ellips-bottom {
    top: 50% !important;
  }
}

@media (min-width: 1200px) {
  .title-jumbotron {
    font-size: 3.5rem;
    width: 75% !important;
  }
}
@media (min-width: 1440px) {
  .title-jumbotron {
    font-size: 4rem;
    width: 70% !important;
  }
}
@media (min-width: 1920px) {
  .title-jumbotron {
    font-size: 5rem;
    width: 70% !important;
  }
  .jumbotron-description {
    width: 80% !important;
  }
}