@media (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
}

@media (min-wdth: 992px) {
  .w-lg-50 {
    width: 50% !important;
  }
}

@media (min-width: 1200px) {
  .w-xl-50 {
    width: 50% !important;
  }
}

.btn-primary {
  background-color: var(--blue-1);
}

.btn-primary:hover {
  background-color: var(--blue-2);
}

.text-yellow {
  color: var(--yellow-1);
}

/* @media (max-width: 768px) {
  .w-md-100 {
    width: 100% !important;
  }
} */

@media (min-width: 768px) {
  .w-md-50 {
    width: 50% !important;
  }
}

@media (max-width: 767px) {
  .hide-on-small {
    display: none !important;
  }
}

/* This hides the element for screens bigger than 768px */
@media (min-width: 768px) {
  .show-on-small {
    display: none;
  }
}

/* This shows the element for screens smaller than 768px */
@media (max-width: 767px) {
  .show-on-small {
    display: block;
  }
}

.text-justify {
  text-align: justify;
}

@media (min-width: 768px) {
  /* adjust the breakpoint according to your needs */
  .hide-on-large {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .w-md-65 {
    width: 65% !important;
  }
  .w-md-75 {
    width: 75% !important;
  }
}

.text-white {
  color: #ffffff;
}

.text-yellow {
  color: #ffdb60;
}

.text-yellow-2 {
  color: #FC9503;
}