.outer {
  width: 100%;
  height: 180vh;
  background: white;
  position: relative;
  overflow: hidden;
  max-height: 1200px;
}

.inner {
  width: 160%;
  height: 180vh;
  background: #FFF7E9;
  border-radius: 50%;
  position: absolute;
  left: -30%;
  top: -25%;
  overflow: visible;
  max-height: 1200px;
}

.container-product-banner {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img-product-banner {
  width: 220px;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.title-product-banner {
  font-weight: 700 !important;
  color: #2E2E2E;
}

.desc-product-banner {
  font-weight: 400 !important;
  color: #686869;
}

.video-container {
  box-shadow: 0px 4px 30px 9px #0000000D;
  border-radius: 15px;
  border: 10px solid #fff;
  aspect-ratio: 2/1;
  background-color: #fff;
  z-index: 1;
  overflow: hidden;
}

.product-video {
  width: 100%;
  height: auto;
  display: block;
}

.feature-detail {
  border: 1px solid #D0D1D2;
  border-radius: 10px;
  cursor: pointer;
}

.feature-detail.active {
  border: 1px solid #FC7429;
  box-shadow: 0 0 0 2px #FC7429;
}

.feature-detail h4 {
  color: #FC7429;
}

@media (min-width: 1200px) {
  .outer {
    height: 170vh;
    max-height: 1150px;
  }
  .inner {
    height: 170vh;
    max-height: 1150px;
  }
  .container-detail-ads .img-fluid {
    width: 85%;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1440px) {
  .container-detail-chat {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .container-detail-ads {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  /* .outer {
    height: 170vh;
    max-height: 1150px;
  }
  .inner {
    height: 170vh;
    max-height: 1150px;
  } */
}
@media (min-width: 1920px) {
  .container-detail-chat {
    padding-right: 25rem !important;
    padding-left: 25rem !important;
  }
  .container-detail-ads {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
  .title-product-banner {
    width: 75%;
    margin-right: auto;
    margin-left: auto;
  }
  .outer {
    max-height: 1100px;
  }
  .inner {
    max-height: 1100px;
  }
}