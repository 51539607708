body .detail {
  background-color: #fbfaff;
}

.breadcrumb {
  --bs-breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='%236c757d'/%3E%3C/svg%3E");
}

.breadcrumb a {
  text-decoration: none;
  color: #fc7429;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.breadcrumb li {
  color: #848484;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.title-author {
  color: #7b7b7b;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.6px; /* 140% */
  letter-spacing: -0.2px;
}

.title-date {
  color: rgba(25, 25, 25, 0.6);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 85.714% */
  letter-spacing: 1.5px;
}

.post-content {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
}

.post-thumbnail {
  box-shadow: 0px 2px 20px 0px #0000001a;
  border-radius: 10px;
}

.post-toc {
  border-radius: 5px;
  border: 1px solid #aaa;
  background: #f9f9f9;
}

.w-40 {
  width: 40%;
}

.post-toc ol {
  counter-reset: section; /* Set a named counter */
  list-style-type: none; /* Remove default numbering */
  color: #FC7429;
}
.post-toc li::before {
  counter-increment: section; /* Increment the counter */
  content: counters(section, ".") ". "; /* Add the counter value before the content */
}
.post-toc li ol {
  counter-reset: subsection; /* Reset the counter for the inner list */
}
.post-toc li ol > li::before {
  counter-increment: subsection; /* Increment the counter for the inner list */
  content: counters(section, ".") "." counters(subsection, ".") " "; /* Combine both counters */
}
.article-container {
  background: #FBFAFF;
}
.post-content h2,
.post-content h3,
.post-content h5,
.post-content p,
.post-content li {
  line-height: max(120%, 28.8px);
}
.post-content h2,
.post-content h3,
.post-content h4 {
  font-weight: 700;
  margin-bottom: 1rem;
}
.post-content h5 {
  font-weight: 700;
}
.post-content p,
.post-content ol {
  margin-bottom: 1.5rem;
}
.post-content ol.bold > li::marker {
  font-weight: 700;
  font-size: 1.25rem;
}
.post-content li {
  margin-bottom: .5rem;
}
.text-italic {
  font-style: italic;
}