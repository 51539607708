.bg-benefits {
    background: #fff7e9;
}
@media (min-width: 1440px) {
    .bg-benefits {
      padding-right: 13rem !important;
      padding-left: 13rem !important;
    }
}
@media (min-width: 1920px) {
    .bg-benefits {
      padding-right: 23rem !important;
      padding-left: 23rem !important;
    }
}

@media (max-width: 768px) {
    .desc-benefit {
        width: 80% !important;
    }
}
@media (min-width: 768px) {
    .desc-benefit {
        width: 100% !important;
    }
}
@media (min-width: 992px) {
    .desc-benefit {
        width: 85% !important;
    }
}
@media (min-width: 1200px) {
    .desc-benefit {
        width: 75% !important;
    }
}