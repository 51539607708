.bg-features {
  background: var(--bg-1);
}

@media (min-width: 500px) and (max-width: 576px) {
  #row-slide-feature {
    height: 600px;
  }

  #slide-img-feature {
    position: absolute;
    bottom: 30px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .features-paragraph {
    font-size: 16px !important;
  }
}

@media (max-width: 576px) {
  .bg-features {
    box-shadow: unset;
  }
  .features-headline {
    font-size: 20px;
  }
  .features-paragraph {
    font-size: 16px !important;
  }
}

@media (min-width: 992px) {
  .features-headline {
    font-size: 26px;
  }
}

@media (min-width: 1400px) {
  .features-headline {
    font-size: 36px;
  }
}

@media (min-width: 1600px) {
  .features-headline {
    font-size: 40px;
  }
  .features-paragraph {
    font-size: 24px !important;
  }
}

@media (min-width: 1920px) {
  .features-paragraph {
    font-size: 28px !important;
  }
  /* .bg-features .img-fluid {
    max-width: 75%;
  } */
}

@media (min-width: 1200px) {
  .features-paragraph {
    font-size: 32px;
  }
}

#features-navigation-arrows.splide__arrow.splide__arrow--prev,
#features-navigation-arrows.splide__arrow.splide__arrow--next {
  opacity: unset;
  background-color: white;
}

#features-navigation-arrows.splide__arrow.splide__arrow--prev {
  position: absolute;
  left: 40px;
  top: 280px;
}

#features-navigation-arrows.splide__arrow.splide__arrow--next {
  position: absolute;
  left: 90px;
  top: 280px;
}

#slide-text-feature {
  margin-bottom: 100px;
}

@media (max-width: 768px) {
  #features-navigation-arrows.splide__arrow.splide__arrow--prev,
  #features-navigation-arrows.splide__arrow.splide__arrow--next {
    top: 280px;
  }
}

@media (min-width: 1024px) {
  #features-navigation-arrows.splide__arrow.splide__arrow--prev,
  #features-navigation-arrows.splide__arrow.splide__arrow--next {
    top: 285px;
  }
}

@media (min-width: 1440px) {
  #features-navigation-arrows.splide__arrow.splide__arrow--prev,
  #features-navigation-arrows.splide__arrow.splide__arrow--next {
    top: 350px;
    width: 40px;
    height: 40px;
  }

  #features-navigation-arrows.splide__arrow.splide__arrow--prev img,
  #features-navigation-arrows.splide__arrow.splide__arrow--next img {
    height: 28px;
  }
}

@media (min-width: 1600px) {
  #features-navigation-arrows.splide__arrow.splide__arrow--prev,
  #features-navigation-arrows.splide__arrow.splide__arrow--next {
    top: 400px;
  }
}

@media (min-width: 1920px) {
  #features-navigation-arrows.splide__arrow.splide__arrow--prev,
  #features-navigation-arrows.splide__arrow.splide__arrow--next {
    width: 45px;
    height: 45px;
  }

  #features-navigation-arrows.splide__arrow.splide__arrow--prev img,
  #features-navigation-arrows.splide__arrow.splide__arrow--next img {
    height: 30px;
  }

  #features-navigation-arrows.splide__arrow.splide__arrow--next {
    left: 110px;
  }
}

@media (min-width: 1440px) {
  .bg-features {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
}
@media (min-width: 1920px) {
  .bg-features {
    padding-right: 18rem !important;
    padding-left: 18rem !important;
  }
}