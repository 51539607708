.btn-whatsapp {
    background: linear-gradient(180deg, #56d263 0%, #1db437 100%);
    width: 182px;
    height: 45px;
  }
  
  .icon-whatsapp {
    background: linear-gradient(180deg, #56d263 0%, #1db437 100%);
  }
  
  .btn-facebook {
    background: linear-gradient(180deg, #1877f2 0%, #155a8a 100%);
    width: 182px;
    height: 45px;
  }
  
  .icon-facebook {
    background: linear-gradient(180deg, #1877f2 0%, #155a8a 100%);
  }
  
  .btn-twitter {
    background: black;
    width: 182px;
    height: 45px;
  }
  
  .icon-twitter {
    background: black;
  }
  