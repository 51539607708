.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Fonts */

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.inter {
  font-family: "Inter", sans-serif;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.lato {
  font-family: "Lato", sans-serif;
}

.sora {
  font-family: "Sora", sans-serif;
}

:root {
  --blue-1: #1053b7;
  --blue-2: #194380;
  --blue-3: #3495e2;
  --bg-1: #181b21;
  --orange-1: #fc7429;
  --orange-2: #fc9503;
  --yellow-1: #ffdb60;
  --yellow-2: #feb628;
}

.text-orange-1 {
  color: var(--orange-1);
}

.text-yellow-2 {
  color: var(--yellow-2);
}

.arch-underline {
  position: relative;
  /* margin-right: 1rem; */
}
.arch-underline:after {
  content: "";
  position: absolute;
  bottom: -25px;
  left: 0;
  height: 21px;
  width: 100%;
  border: solid 5px var(--yellow-2);
  border-color: var(--yellow-2) transparent transparent transparent;
  border-radius: 50%;
  transform: rotate(0deg); /* Set rotation to 0deg */
}

.w-65 {
  width: 65% !important;
}

.w-70 {
  width: 70% !important;
}

.w-85 {
  width: 85% !important;
}

.container-vertical-padding {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.container-vertical-padding-2 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.container-top-padding {
  padding-top: 4rem !important;
}

::-webkit-scrollbar {
  width: 6px;
  background: #262626;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #ED5129;
}
::-webkit-scrollbar-thumb:hover {
  background: #ED5129;
}

h1.fw-bold {
  line-height: 1.75;
}