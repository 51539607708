@media (min-width: 1440px) {
    .bg-facts {
      padding-right: 10rem !important;
      padding-left: 10rem !important;
    }
  }
  @media (min-width: 1920px) {
    .bg-facts {
      padding-right: 18rem !important;
      padding-left: 18rem !important;
    }
  }