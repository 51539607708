.bg-advantages {
  background: #fff7e9;
}

.advantages-subtitle {
  color: #8b8b8c;
}

.advantages-switch {
  border-color: #fc7429 !important;
  cursor: pointer;
}

.advantages-switch .active {
  background-color: #fc7429;
  color: white;
  font-weight: 700;
}

.advantages-switch .inactive {
  color: #8b8b8c;
}

.advantages-list {
    list-style: none;
}

@media (min-width: 384px) {
  .advantages-switch {
    width: 100% !important;
  }
}

@media (min-width: 768px) {
  .advantages-switch {
    width: 75% !important;
  }
}

@media (min-width: 1440px) {
  .bg-advantages {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
}
@media (min-width: 1920px) {
  .bg-advantages {
    padding-right: 20rem !important;
    padding-left: 20rem !important;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .bg-advantages .img-fluid {
    width: 75% !important;
    margin-left: auto;
    margin-right: auto;
  }
}