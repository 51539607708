.w-65 {
  width: 65%;
}

.search-bar {
  font-size: 14px;
  border: 1px solid #3a3a3d;
}

.search-bar::placeholder {
  color: #7f7f7f;
}

.search-icon {
  background-color: #fc9503;
  border: 1px solid #3a3a3d;
}

.main-post-card {
  border: none;
  background-color: #fbfaff;
}

.main-post-card-body {
  padding-top: 7px;
}

.main-post-image {
  box-shadow: 0px 2px 20px 0px #0000001a;
  border-radius: 10px;
}

.mini-title {
  color: #7b7b7b;
  font-size: 13px;
}

.mini-date {
  font-size: 10px;
}

.main-post-headline {
  font-size: 21px;
}

.main-post-text {
  font-size: 12.5px;
  margin-bottom: 0px;
}

.main-post-link {
  padding-top: 0px;
  font-size: 12.5px;
}

.post-date {
  color: rgba(25, 25, 25, 0.6);
  font-family: Inter;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px; /* 120% */
  letter-spacing: 1.5px;
}

.card-img-top,
.cards {
  box-shadow: 0px 2px 20px 0px #1919191a;
}

.card-img-top {
  border-radius: 25px 25px 0px 0px;
  max-height: 200px;
  object-fit: cover;
  object-position: center;
}

.cards {
  border: none;
  border-radius: 25px;
  width: 80%;
}

.btn-yellow {
  background-color: white;
  border: 1px solid #fc9503;
  color: #fc9503;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  border-radius: 10px;
}

.btn-yellow:hover {
  background-color: #fc9503;
  color: white;
}

.blog .swiper-pagination-bullet {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  font-weight: bold;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0.1);
  font-family: "Inter", sans-serif;
}

.blog .swiper-pagination-bullet-active {
  color: #fff;
  background: #fc9503;
}

.posts .col-4 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.post-navigation a {
  color: black;
}

.post-navigation a:hover {
  color: #1053b7;
}

.post-navigation a img {
  transition: filter 0.3s ease; /* Smooth transition for the darkening effect */
}

.post-navigation a:hover img {
  filter: brightness(50%); /* Darkens the image on hover */
}

@media (min-width: 768px) {
  .blog .swiper-pagination-bullet {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

@media (min-width: 769px) {
  .w-lg-40 {
    width: 40% !important;
  }
}

@media (min-width: 1023px) {
  .w-md-60 {
    width: 60% !important;
  }
}

@media (max-width: 768px) {
  .hide-on-tablet {
    display: none;
  }
  .show-on-tablet {
    display: block;
  }
}
/* Custom styles for small screens */
@media (max-width: 575.98px) {
  .breadcrumb-item {
    font-size: 0.75rem; /* Adjust the font size */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflowed content */
    text-overflow: ellipsis; /* Add ellipsis for overflowed content */
    max-width: 150px; /* Adjust maximum width */
  }
}
@media (max-width: 768px) {
  .profile-img img {
    width: 50px;
  }

  .post-thumbnail {
    margin-top: 0px !important;
    margin-bottom: 36px !important;
  }

  .px-5 {
    padding-left: 10px; /* decrease padding more */
    padding-right: 10px;
  }

  /* h2:not(.post-content h2),
  h4:not(.post-content h4) {
    font-size: 1.2rem; 
  } */

  .post-content {
    padding: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
}

@media (max-width: 768px) {
  .post-toc {
    padding: 0px !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .post-toc ol {
    padding-left: 1rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .social-media-share {
    justify-content: center !important;
  }
}
