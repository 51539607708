.sticky-top {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0px 4px 15px 0px #00000026;
}

.btn-login {
  background: #FC7429;
  border-radius: 50px;
  color: #fff;
}

.btn-login:hover {
  color: #FC7429;
  box-shadow: 0px 4px 16px 0px #00000033, 0 0 0 2px #FC7429;
}

@media (min-width: 1024px) {
  .navbar {
    padding: 0 3rem 0;
  }
}

@media (min-width: 992px) {
  #dropdown-language {
    display: block !important;
  }
}

@media (min-width: 992px) {
  #dropdown-language-mobile {
    display: none !important;
  }
}

@media (min-width: 1440px) {
  .navbar {
    padding: 0 6rem 0;
  }
}

.nav-link:focus-visible {
  box-shadow: none !important;
}