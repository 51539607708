#clients-navigation-arrows.splide__arrow.splide__arrow--prev,
#clients-navigation-arrows.splide__arrow.splide__arrow--next {
  opacity: unset;
  background-color: white;
}

.brand-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: calc(250px * 4);
  height: fit-content;
  gap: 40px 0;
}

.brand-list-item {
  width: 100%;
  height: 90px;
  /* display: flex;
      align-items: center; */
  position: relative;
  overflow: hidden;
}

.items {
  padding: 0;
  max-width: 250px;
}

.brand-list-item .brand-logo {
  transition: all .8s ease-in-out;
  margin-top: 0;
}

.brand-list-item .brand-logo.animated {
  transition: all .8s ease-in-out;
  margin-top: -90px;
}

.brand-list-item img {
  height: 90px;
}
